import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Configuration } from '../constant/configuration';
import { NavigationEnd, Router } from '@angular/router';

interface IMetaTag {
    titleTranslationKey: string;
    titleInterpolateParams?: { [key: string]: string };
    descriptionTranslationKey?: string;
    descriptionInterpolateParams?: { [key: string]: string };
    description?: string;
    imageUrl?: string;
}

@Injectable({ providedIn: 'root' })
export class SeoTagsService {
    currentMeta: IMetaTag;
    isLastNavigatedComponentDefinedSeoTags = false;
    constructor(
        private translateService: TranslateService,
        private configuration: Configuration,
        private title: Title,
        private meta: Meta,
        private router: Router
    ) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (!this.isLastNavigatedComponentDefinedSeoTags) {
                    this.defineDefaultMetaTags();
                }
                // reset flag
                this.isLastNavigatedComponentDefinedSeoTags = false;
            }
        });
    }

    initialize(): void {
        this.defineDefaultMetaTags();

        this.translateService.onLangChange.subscribe(() => {
            if (this.currentMeta) {
                this.updateSEOTags(this.currentMeta);
            }
        });
    }

    defineDefaultMetaTags(): void {
        this.updateSEOTags(
            {
                titleTranslationKey: 'global.title',
                description: 'global.description'
            },
            false
        );
    }

    updateSEOTags(payload: IMetaTag, componentCall = true): void {
        this.isLastNavigatedComponentDefinedSeoTags = componentCall;
        this.currentMeta = payload;

        const title = this.generateTitleFromMetaTag(payload);
        const description = this.generateDescriptionFromMetaTag(payload);

        this.title.setTitle(title);
        this.meta.updateTag({ property: 'og:title', content: title });
        this.meta.updateTag({ name: 'description', content: description });
        this.meta.updateTag({ property: 'og:description ', content: description });

        if (payload.imageUrl) {
            this.meta.updateTag({ property: 'og:image', content: payload.imageUrl });
        } else {
            this.meta.updateTag({ property: 'og:image', content: 'images/logo/lexnow_big.png' });
        }
    }

    generateTitleFromMetaTag(payload: IMetaTag): string {
        return `${this.configuration.appName} |
          ${
              this.translateService.instant(payload.titleTranslationKey, payload.titleInterpolateParams || {}) as string
          }`;
    }

    generateDescriptionFromMetaTag(payload: IMetaTag): string {
        let description = '';
        if (payload.description) {
            description = payload.description;
        } else if (payload.descriptionTranslationKey) {
            description = this.translateService.instant(
                payload.descriptionTranslationKey,
                payload.descriptionInterpolateParams || {}
            ) as string;
        }

        return description;
    }
}
