import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AutoLoginComponent } from './auto-login.component';
import { AUTH_GUARD } from './guards/auth.guard';
import { CURRENT_USER_RESOLVER } from './resolvers/current-user.resolver';

const routes: Routes = [
    {
        path: 'auto/:company/:hash',
        component: AutoLoginComponent
    },
    {
        path: '',
        // eslint-disable-next-line @typescript-eslint/promise-function-async
        loadChildren: () => import('./offline/main-offline.module').then(m => m.MainOfflineModule)
    },
    {
        path: 'public',
        resolve: { currentUser: CURRENT_USER_RESOLVER.resolve },
        // eslint-disable-next-line @typescript-eslint/promise-function-async
        loadChildren: () => import('./public/main-public.module').then(m => m.MainPublicModule)
    },
    {
        path: 'o',
        canMatch: [AUTH_GUARD.canMatch],
        resolve: { currentUser: CURRENT_USER_RESOLVER.resolve },
        // eslint-disable-next-line @typescript-eslint/promise-function-async
        loadChildren: () => import('./online/main-online.module').then(m => m.MainOnlineModule)
    },
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            paramsInheritanceStrategy: 'always'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
