import { Configuration } from '../../../constant/configuration';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TimesheetSharedService {
    public formattedTiming = '00:00:00';

    public elapsedTime = 0;
    public deltaTime = 0;

    public description = '';
    public reference = '';

    isRunning = false;

    // constants
    private HOUR_TO_MILLIS = 3600000;
    private MIN_TO_MILLIS = 60000;
    private SEC_TO_MILLIS = 1000;
    private MIN_TO_SECS = 60;

    constructor(public configuration: Configuration) {}

    updateTimerWithFormattedTime(time, noSeconds): void {
        const roundAlgorithm = time < 0 ? Math.ceil : Math.floor;

        const diffInHrs = time / this.HOUR_TO_MILLIS;
        const hours = roundAlgorithm(diffInHrs);

        const diffInMin = (diffInHrs - hours) * this.MIN_TO_SECS;
        const minutes = roundAlgorithm(diffInMin);
        let seconds = 0;
        if (!noSeconds) {
            const diffInSec = (diffInMin - minutes) * this.MIN_TO_SECS;
            seconds = roundAlgorithm(diffInSec);
        }

        const formattedHH = (minutes < 0 && hours === 0 ? '-' : '') + hours.toString().padStart(2, '0');
        const formattedMM = Math.abs(minutes).toString().padStart(2, '0');
        const formattedSS = Math.abs(seconds).toString().padStart(2, '0');
        this.elapsedTime = hours * this.HOUR_TO_MILLIS + minutes * this.MIN_TO_MILLIS + seconds * this.SEC_TO_MILLIS;
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        this.formattedTiming = `${formattedHH}:${formattedMM}:${formattedSS}`;
    }

    incrementTimerMinutes(min: number): void {
        this.elapsedTime += min * this.MIN_TO_MILLIS;
        this.deltaTime += min * this.MIN_TO_SECS;
        this.updateTimerWithFormattedTime(this.elapsedTime, true);
    }

    decrementTimerMinutes(min: number): void {
        this.elapsedTime -= min * this.MIN_TO_MILLIS;
        if (this.elapsedTime < 0) {
            this.elapsedTime = 0;
        } else {
            this.deltaTime -= min * this.MIN_TO_SECS;
        }
        this.updateTimerWithFormattedTime(this.elapsedTime, true);
    }

    resetTimer(): void {
        this.elapsedTime = 0;
        this.formattedTiming = '00:00:00';
        this.description = '';
        this.reference = '';
        this.isRunning = false;
    }

    setReferenceDescription(reference: string, description: string): void {
        this.reference = reference;
        this.description = description;
    }

    resetDeltaTime(): void {
        this.deltaTime = 0;
    }
}
