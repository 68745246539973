import { AppRoutingModule } from './app.routing.module';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { MainComponent } from './main/main.component';
import { AutoLoginComponent } from './auto-login.component';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NotificationComponent } from './components/notification/notification.component';
import { FileSaverModule } from 'ngx-filesaver';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieModule } from 'ngx-cookie';
import { TimesheetRecordingComponent } from './online/timesheet/timesheet.recording.component';
import { InfoPopupComponent } from './online/timesheet/infopopup/infopopup.component';
import { SearchSubMenuComponent } from './online/document/result/search-sub-menu/search-sub-menu.component';
import { SpinnerComponent } from './shared/directive/spinner/spinner.component';
import { MainForgetDialogComponent } from './offline/forget-dialog/main-forget-dialog.component';
import { MessageBus } from './messaging/MessageBus';
import { Configuration } from './constant/configuration';
import { NotificationMessageService } from './services/notification_message.service';
import { TimesheetSharedService } from './online/timesheet/service/timesheet.shared.service';
import { HomeTop10SearchDialogComponent } from './online/home/home-top10-search/home-top10-search-dialog.component';
import { TranslationLoadedService } from './translate/translation-loaded.service';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import { HttpClientInterceptor } from './interceptors/httpClientInterceptor';
import { SpinnerService } from './shared/directive/spinner/spinner.service';
import { AppComponent } from './app.component';
import { LatestDocumentsTabsComponent } from './online/home/latest-documents-tabs.component';
import initializeTranslations from './hooks/initiliaze-translations.hook';
import externalTranslationsHook from './hooks/external-translations.hook';
import { CriteriaTreeDropdownComponent } from './online/search/search-bar/criteria-tree-dropdown/criteria-tree-dropdown.component';
import { SandboxComponent } from './sandbox/sandbox.component';
import { LatestDocumentsOrderPipe } from './online/home/latest-documents-order.pipe';
import { FormatMinutesHoursPipe } from './online/streams-and-buckets/pipes/format-minutes-hours.pipe';
import { ParseRomanPipe } from './filter/parse-roman.pipe';
import { RestrictedAccessPopupComponent } from './shared/components/restricted-access-popup/restricted-access-popup.component';
import { enableElfProdMode } from '@ngneat/elf';
import { devTools } from '@ngneat/elf-devtools';
import { environment } from '../environments/environment';
import { CommonSharedModule } from './modules/common-shared/common-shared.module';
import { Router, RouteReuseStrategy } from '@angular/router';
import { CustomRouteReuseStrategy } from './hooks/custom-route-reuse-strategy';
import { AdministrationTranslationService } from './online/administration/translations/service/administration-translation.service';
import { AdministrationCompanySharedModule } from './online/administration/company/administration-company-shared.module';
import { MatDialog } from '@angular/material/dialog';
import TranslationLoaderFactory from './hooks/translation-loader-factory.hook';
import { MyMissingTranslationHandler } from './translate/MyMissingTranslationHandler';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_DATE_FNS_FORMATS, DateFnsAdapter } from '@angular/material-date-fns-adapter';

registerLocaleData(localeEn, 'en');
registerLocaleData(localeNl, 'nl');
registerLocaleData(localeFr, 'fr');

if (environment.production) {
    enableElfProdMode();
} else {
    devTools();
}
const DEFAULT_LANG = 'fr';
@NgModule({
    imports: [
        AppRoutingModule,
        TranslateModule.forRoot({
            defaultLanguage: DEFAULT_LANG,
            loader: {
                provide: TranslateLoader,
                useFactory: TranslationLoaderFactory,
                deps: [TranslationLoadedService]
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: MyMissingTranslationHandler,
                deps: [Injector]
            }
        }),
        CookieModule.forRoot(),
        BrowserModule,
        FileSaverModule,
        BrowserAnimationsModule,
        HttpClientModule,
        CommonSharedModule,
        AdministrationCompanySharedModule
    ],
    providers: [
        { provide: 'window', useValue: window },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeTranslations,
            deps: [TranslationLoadedService, MessageBus, TranslateService, Configuration],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: externalTranslationsHook,
            deps: [AdministrationTranslationService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpClientInterceptor,
            deps: [SpinnerService, NotificationMessageService, Router, MessageBus, Configuration, MatDialog],
            multi: true
        },
        {
            provide: RouteReuseStrategy,
            useClass: CustomRouteReuseStrategy
        },
        { provide: MAT_DATE_LOCALE, useValue: DEFAULT_LANG },
        {
            provide: DateAdapter,
            useClass: DateFnsAdapter,
            deps: [MAT_DATE_LOCALE]
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS },
        TimesheetSharedService,
        FormatMinutesHoursPipe,
        ParseRomanPipe
    ],
    declarations: [
        AppComponent,
        MainForgetDialogComponent,
        NotificationComponent,
        MainComponent,
        AutoLoginComponent,
        TimesheetRecordingComponent,
        InfoPopupComponent,
        SearchSubMenuComponent,
        SpinnerComponent,
        LatestDocumentsTabsComponent,
        LatestDocumentsOrderPipe,
        HomeTop10SearchDialogComponent,
        SandboxComponent,
        CriteriaTreeDropdownComponent,
        RestrictedAccessPopupComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
